<!-- 考试大类 -->
<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
/deep/ .el-form-item__content {
  line-height: inherit;
}

/deep/.el-form-item {
  margin-bottom: 0;
}

/deep/.el-pagination__jump {
  margin-left: 0;
  margin-right: 10px;
}
.queryForm .el-select,.queryForm .el-input{
  width: 150px !important;
}
.check-distri {
  line-height: 32px;
}
.el-tag{
  cursor: pointer;
}
/deep/ .el-dialog__header {
  background: rgb(197 197 197 / 40%) !important;
  padding: 20px;
}
</style>
<script>
import Layout from "@/views/layouts/main";
import {
  listPage,
  infoerMationEdit,
  findUser,
  info,
  removeUser,
  removeFile,
  delInformation,
  memberList, notice
} from "@/api/admin/information/informationCollaboration.js"
import {checkFile, getUploadLimit, uploadFiles} from "@/api/common/public";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,

  },
  data() {
    return {
      wjlx:[
          '公告文件','考试数据','数据样张'
      ],
      yxDict: [{label: '有效状态', value: ''}, {label: '有效', value: '1'},{label: '已过期', value: '0'}],
      query: {
        keyword: '',
        yxzt: '',
      },
      uploadModule: 'Information',
      uploadLimit:{},
      items: [{
        text: "用户首页",
        href: "/admin"
      },
        {
          text: "基础大类",
          active: true
        }
      ],
      exportTitle: "考试类别导出",
      showmodal: false,
      dialogTitle: "添加考试类别",
      module: "KSDL",
      tableList: [],
      allDataList: [],
      addForm: {
        lbbm: "",
        lbmc: "",
        lbsx: "资格类",
        qyzt: true,
        pxh: 0,
      },
      pageData: {
        pageNum: 1,
        pageSize: 50,
        total: 0
      },
      isIndeterminate: false,
      checkList: [],
      memberShow: false,
      memberList: [],
      memberSearch: {
        keyword: ''
      }
    };
  },
  methods: {
    // 删除
    deleteItem(obj) {
      var checkboxes = document.querySelectorAll('.checkRow');
      let list = []
      checkboxes.forEach(function(checkbox) {
        if(checkbox.checked) {
          list.push(checkbox.value)
        }
      });
      if(obj.infoId && list.length) {
        return;
      }
      if(obj.infoId) {
        this.$confirm(`是否确认删除【${obj.wjbt}】?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delInformation( [obj.infoId]).then(res => {
            if (res.status) {
              this.$message({
                type: "success",
                message: '删除成功',
              });
              this.getList();
            }
          });
        }).catch(() => {

        });
      }else {
        this.$confirm(`是否确认批量删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delInformation(list).then(res => {
            if (res.status) {
              this.$message({
                type: "success",
                message: '删除成功',
              });
              this.getList();
            }
          });
        }).catch(() => {

        });
      }
    },
    // 获取数据列表
    getList() {
      listPage({...this.query, ...this.pageData}).then(res => {
        if (res.status) {
          this.tableList = res.data
          this.pageData.total = res.total
        }
      })
    },
    addItem() {
      this.showmodal = true
      this.dialogTitle = "发起信息协同"
      this.addForm = {
        fileList: [],
        memberList: []
      }
    },
    editItem(data) {
      this.addForm = {}
      this.showmodal = true
      this.dialogTitle = "修改信息协同"
      info(data.infoId).then(res => {
        if(res.status) {
          this.addForm = res.data
        }
      })
    },
    submit() {
      infoerMationEdit(this.addForm).then(res => {
          if (res.status) {
            this.showmodal = false;
            this.getList();
            this.$message({
              type: "success",
              message: this.addForm.infoId?'修改成功':'添加成功',
            });
          }
      });
    },

    handleSizeChange(val) {
      this.pageData.pageSize = val
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.pageNum = val
      this.getList();
    },
    // 数据导入之后刷新列表
    uploadSuccess() {
      this.getList()
    },
    limit() {
      getUploadLimit({
        module: this.uploadModule,
      }).then(res => {
        if (res.status) {
          this.uploadLimit["ext"] = "." + res.data.extLimit.replaceAll(",", ",.");
          this.uploadLimit["size"] = res.data.sizeLimit;
        }
      });
    },
    uploadFile(file) {
      /** 上传背景文件 */
      var _this = this;
      file = file.raw;
      if (checkFile(_this, this.uploadLimit.size, this.uploadLimit.ext, file)) {
        _this.uploadStatus = "loading";
        uploadFiles(_this.uploadModule, file).then(res => {
          if (res.status) {
            var file = res.data[0];
            if (file.status) {
              this.addForm.fileList = [...this.addForm.fileList||[],{...file, wjm: file.name, wjdz: file.fileId, bclj: file.path}]
              _this.$message({
                type: "success",
                message: "上传成功"
              });
              this.fileUp = true
            } else {
              _this.$message({
                type: "error",
                message: file.error
              });
            }
          }
          _this.uploadStatus = "";
        });
      }
    },
    handleUserClose(data) {
      this.$confirm(`是否确认删除”${data.sjr}“?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeUser(this.addForm.infoId, data.memberId).then((res) => {
          if (res.status) {
            this.addForm.userList.filter(f => { return f.sjrzh!=data.sjrzh})
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    handleFileClose(data) {
      this.$confirm(`是否确认删除文件”${data.wjm}“?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeFile(this.addForm.infoId, data.fileId).then((res) => {
          if (res.status) {
            this.addForm.fileList.filter(f => { return f.fileId!=data.fileId})
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    addUser(){
      if(!this.addForm.yddhStr) {
        return;
      }
      findUser({yddh: this.addForm.yddhStr}).then(res => {
        if(res.status) {
          res.data = res.data.map(m => {
            return {infoId: this.addForm.infoId||null, sjrzh: m.yhzh, sjr: m.xm, yddh: m.yddh}
          })
          this.addForm.userList = [...this.addForm.userList||[], ...res.data]
          this.addForm.userList.filter((item, index, self) =>
                  index === self.findIndex(t => (
                      t.sjr === item.sjr
                  ))
          );
          this.$forceUpdate()
        }
      })
    },
    isDateExpired(dateString) {
      if(!dateString) {
        return;
      }
      // 将字符串日期转换为Date对象
      // 假设dateString的格式为"YYYY-MM-DD"
      const dateParts = dateString.split('-');
      const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]); // 注意月份是从0开始的

      // 获取当前日期（不包含时间部分）
      const now = new Date();
      now.setHours(0, 0, 0, 0); // 将当前时间设置为当天的开始

      // 比较两个日期
      return date >= now;
    },
    openFile(fileId) {
      window.open(this.getFileUrl(fileId, false), "_blank");
    },
    // 全选
    checkAll() {
      var checkboxes = document.querySelectorAll('.checkRow');
      checkboxes.forEach(function(checkbox) {
        checkbox.checked = document.getElementById('all').checked;
      });
    },
    // 全选
    checkMemberAll() {
      var checkboxes = document.querySelectorAll('.memberRow');
      checkboxes.forEach(function(checkbox) {
        checkbox.checked = document.getElementById('mem').checked;
      });
    },
    openMember(item){
      this.memberShow = true;
      this.memberSearch.infoId = item.infoId
      this.memberSearch.keyword = ''
      this.addForm.wjbt = item.wjbt

      memberList(this.memberSearch).then((res) => {
        if (res.status) {
          this.memberList = res.data
        }
      });
    },
    searchMember() {
      memberList(this.memberSearch).then((res) => {
        if (res.status) {
          this.memberList = res.data
        }
      });
    },
    notice() {
      var checkboxes = document.querySelectorAll('.memberRow');
      let list = []
      checkboxes.forEach(function(checkbox) {
        if(checkbox.checked) {
          list.push(checkbox.value)
        }
      });
      if(list.length == 0) {
        return;
      }
      let memberList =  this.memberList.filter(member => list.includes(member.memberId));
      notice({...this.addForm, userList: memberList}).then((res) => {
        if (res.status) {
          this.$message({
            type: "success",
            message: "发送成功!",
          });
        }
      });
    }
  },
  mounted() {
    this.getList()
    this.limit();
  }

};
</script>

<template>
  <Layout>

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList queryForm">
                <el-select class=" mr-2 " style="width: 10%;" clearable placeholder="有效状态" v-model="query.yxzt"   size="small">
                  <el-option :value="item.value" v-for="item in yxDict" :key="item.value" :label="item.label">
                    {{item.label}}
                  </el-option>
                </el-select>
                <el-input class=" mr-2 " placeholder="查询标题/发送人"  v-model="query.keyword" size="small" maxlength="50" >
                </el-input>
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="getList"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>

                <b-button @click="addItem" variant="outline-info" class="flexList mr-2 condition"><i
                    class="iconfont el-icon-circle-plus-outline mr-2 font-size-20"></i>发起信息通知</b-button>

                <b-button @click="deleteItem({})" variant="outline-info" class="flexList mr-2 condition red" ><i
                    class="iconfont el-icon-delete mr-2 font-size-20"></i>批量删除</b-button>

              </div>

            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                <tr>

                  <th style="width: 80px;">
                    <input type="checkbox" id="all" name="all" @change="checkAll" /> 序号</th>
                  <th>标题</th>
                  <th style="width: 8%;">文件类型</th>
                  <th style="width: 10%;">发送人</th>
                  <th style="width: 150px;">发送时间</th>
<!--                  <th style="width: 10%;">收件人</th>-->
<!--                  <th style="width: 150px;">收件时间</th>-->
                  <th style="width: 8%;">文件时效性</th>
                  <th style="width: 8%;">收件人</th>
                  <th style="width: 100px;">操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(obj,index) in tableList" :key="index">

                  <td>
                    <input type="checkbox" name="infoId" :value="obj.infoId" class="checkRow"/>
                    {{index+1+(pageData.pageNum-1)*pageData.pageSize}}
                  </td>
                  <td>{{obj.wjbt}}</td>
                  <td>{{obj.wjlx}}</td>
                  <td> {{obj.fsr}}</td>
                  <td> {{obj.fssj}}</td>
<!--                  <td> </td>-->
<!--                  <td> </td>-->
                  <td :style="{color:isDateExpired(obj.yxsj)||obj.wjsxx == 0?'blue':'red'}"> {{obj.wjsxx == 0 ?'长期有效':obj.yxsj}}{{obj.wjsxx == 0 ?'':'前'}}</td>

                  <td>
                    <a href="javascript:void(0);" @click="openMember(obj)">查看</a>
                  </td>
                  <td class="tab-icon"><i class="iconfont icon-edit-two align-middle mr-1"
                                          @click='editItem(obj)'></i>&emsp;
                    <i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                                                                        @click="deleteItem(obj)"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                             :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                             layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>

    <!-- 弹窗开始 -->
    <!-- 添加-->
    <el-dialog id="addClass" :visible.sync="showmodal" :close-on-click-modal="false" :title="this.dialogTitle" no-close-on-backdrop title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>文件标题：</label>
              <div class=" col-sm-10 p-0 flexList">
                <el-input size="small" style="width: 100%;" maxlength="100" placeholder="输入文件标题"
                       v-model="addForm.wjbt" />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>简要描述：</label>
              <div class="col-sm-10  p-0">
                <el-input size="small" type="textarea" rows="4" style="width: 100%;" maxlength="500" placeholder="简要描述" v-model="addForm.wjms" />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>文件类型：</label>
              <div class="col-sm-10  p-0">
                  <el-radio-group size="small" v-model="addForm.wjlx">
                    <el-radio-button :label="item" :key="item" v-for="item in wjlx">{{ item }}</el-radio-button>
                  </el-radio-group>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>发布时间：</label>
              <el-date-picker
                  v-model="addForm.fssj"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  type="datetime"
                  placeholder="选择发布时间"
                  default-time="12:00:00">
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">时&ensp;效&ensp;性：</label>
              <div class="col-sm-10 p-0 ">
                <el-radio-group size="small" v-model="addForm.wjsxx"  >
                  <el-radio-button label="0">长期有效</el-radio-button>
                  <el-radio-button label="15">15天</el-radio-button>
                  <el-radio-button label="30">30天</el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>添加用户：</label>
              <div style="width: 80%;">
                <el-input v-model="addForm.yddhStr" type="textarea" maxlength="1000" style="width: 80%;" placeholder="输入手机号码，多个用英文逗号分开"></el-input>
                <el-button size="mini" style="margin-left: 10px;" @click="addUser"><i class="iconfont mr-1 el-icon-user"></i>添加用户</el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>发送用户：</label>
              <div v-if="addForm.userList&&addForm.userList.length">
                <el-tag
                    v-for="(user, index) in addForm.userList"
                    :key="index"
                    class="mb-2 mr-2"
                    type="success"
                    closable
                    @close="handleUserClose(user)"
                >
                  {{ user.sjr }}
                </el-tag>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>上传文件：</label>
              <div class="col-sm-10 p-0 ">
                <el-upload class="upload-demo" style="width: 100%;text-align: left;" action="" :auto-upload="false" :on-change="uploadFile" size="small"
                           :accept="uploadLimit.ext" :show-file-list="false">
                  <el-button size="mini" plain style="display: inline;"><i
                      class="iconfont mr-1 icon-shangchuan"></i>上传文件</el-button>
                  <el-tag type="danger" class=" ml-2" style="height: 30px;">
                    支持上传{{ uploadLimit.ext }}文件，且不超过{{
                      uploadLimit.size / 1024 / 1024
                    }}M</el-tag>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>已传文件：</label>
              <div class="col-sm-10 p-0 ">
                <div v-if="addForm.fileList && addForm.fileList.length">
                  <el-tag
                      v-for="(file, index) in addForm.fileList"
                      :key="index"
                      class="mb-2"
                      type="info"
                      @click="openFile(file.wjdz)"
                      closable
                      @close="handleFileClose(file)"
                  >
                    {{ file.wjm }}
                  </el-tag>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
                                              @click="submit">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </el-dialog>
    <el-dialog  :visible.sync="memberShow" title="收件人名单" no-close-on-backdrop title-class="font-18" hide-footer >
      <div style="height: 50vh;overflow-y: auto;">
        <div style="margin-bottom: 10px;">
        <el-input class=" mr-2 " style="width: 200px;" placeholder="查询收件人"  v-model="memberSearch.keyword" size="small" maxlength="50" >
        </el-input>
        <button type="button" class="btn btn-info h30 mr-2" @click="searchMember"><i
            class="iconfont mr-2 icon-mb-search"></i>查询</button>
          <button type="button" class="btn btn-info h30 mr-2" @click="notice"><i
              class="iconfont mr-2 icon-mb-search"></i>批量发送短信通知</button>
        </div>
        <table class="table  light-table table-hover ">
          <thead class="thead-light">
          <tr>
            <th style="width: 50px;">序号
              <input type="checkbox" id="mem" name="all" @change="checkMemberAll" />
            </th>
            <th >账号</th>
            <th style="width: 120px;">收件人 </th>
            <th style="width: 120px;">电话</th>
            <th style="width: 120px;">类型</th>
            <th style="width: 120px;">下载时间</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(e,index) in memberList" :key="index">
            <td>
              <input type="checkbox" name="memberId" :value="e.memberId" class="memberRow"/>
              {{index+1}}</td>
            <td>{{e.sjrzh}}</td>
            <td>{{e.sjr}}</td>
            <td>{{e.yddh}}</td>
            <td>{{e.fslx}}</td>
            <td>{{e.xzsj||'未下载'}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </el-dialog>
  </Layout>
</template>
